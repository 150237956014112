<script setup>
import { ref } from 'vue';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { getDateWithoutTime, getLocalFormattedDateAndTime } from '../../helpers/dateFormatHelper';
import {
  copyToClipboard,
  isNotEmptyArray,
  isNonEmptyString,
  isNullOrEmptyString
} from '../../helpers/utilsHelper';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { generateReport } from '../../helpers/mapPointsReportGeneratorHelper';

const RepositoryMapPointsReports = RepositoryFactory.get('mapPointsReports');

const props = defineProps({ orderId: Number });
const emit = defineEmits(['onMapPointsReportDeleted']);
const dictTableKey = ref(0);

function getLinkForElement(element) {
  return window.location.origin + '/mapPoints/' + element.uuid;
}

function onShowMapButtonClicked(element) {
  if (isNonEmptyString(getLinkForElement(element))) {
    window.open(getLinkForElement(element), '_blank');
  }
}

function onCopyLinkButtonClicked(element) {
  copyToClipboard(getLinkForElement(element), 'Link został skopiowany do schowka.');
}

function onDownloadReportButtonClicked(element) {
  Swal.fire(
    getSwalConfiguration('Pobieranie raportu', 'Czy na pewno chcesz pobrać wybrany raport?')
  ).then((result) => {
    if (result.value) {
      generatePointsReport(element);
    }
  });
}

function generatePointsReport(element) {
  RepositoryMapPointsReports.fetchReportData(element.uuid)
    .then((data) => {
      generateReport(data, 'Raport z zestawu punktów');
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function getDemPointsCount(element) {
  if (isNullOrEmptyString(element?.content)) return null;

  const content = JSON.parse(element.content);

  return isNotEmptyArray(content?.demPoints) ? content.demPoints.length : null;
}

function getActionsCount(element) {
  if (isNullOrEmptyString(element?.content)) return null;

  const content = JSON.parse(element.content);

  return isNotEmptyArray(content?.actions) ? content.actions.length : null;
}

function onDeleteButtonClicked(element) {
  Swal.fire(
    getSwalConfiguration(
      'Usuwanie zestawu punktów',
      'Czy na pewno chcesz usunąć wybrany zestaw punktów?'
    )
  ).then((result) => {
    if (result.value) {
      deleteElement(element);
    }
  });
}

async function deleteElement(element) {
  var response = await RepositoryMapPointsReports.deletePGSharing(element?.id);
  handleResponseWithMessages(response, 'Zestaw punktów został usunięty.', onElementDeleted);
}

function onElementDeleted() {
  emit('onMapPointsReportDeleted');
  dictTableKey.value++;
}
</script>

<template>
  <DictionaryTable
    :key="dictTableKey"
    v-if="props.orderId != null"
    :showHeader="true"
    header="Wygenerowane zestawy punktów"
    :showBorder="false"
    :url="`order/${props.orderId}/PGSharings`"
    :columns="[
      { key: 'name', header: 'Nazwa' },
      { key: 'created_date', header: 'Data udostępnienia', computed: true },
      { key: 'demPoints_count', header: 'Liczba punktów', computed: true },
      { key: 'actions_count', header: 'Liczba akcji', computed: true }
    ]"
    :customButtons="true"
    headerCustomStyle="padding: 0px"
    contentCustomStyle="padding: 0px"
  >
    <template #created_date="{ element }">
      {{
        element.created_at != null
          ? getDateWithoutTime(getLocalFormattedDateAndTime(element.created_at))
          : '-'
      }}
    </template>

    <template #demPoints_count="{ element }">
      {{ getDemPointsCount(element) ?? '-' }}
    </template>

    <template #actions_count="{ element }">
      {{ getActionsCount(element) ?? '-' }}
    </template>

    <template #buttons="{ element }">
      <div style="display: flex; gap: 5px">
        <button
          type="button"
          class="btn btn-outline-primary btn-xs"
          @click="onShowMapButtonClicked(element)"
          onclick="event.stopPropagation()"
        >
          Pokaż mapę
        </button>

        <button
          type="button"
          class="btn btn-outline-primary btn-xs"
          @click="onCopyLinkButtonClicked(element)"
          onclick="event.stopPropagation()"
        >
          Skopiuj link
        </button>

        <button
          type="button"
          class="btn btn-outline-primary btn-xs"
          @click="onDownloadReportButtonClicked(element)"
          onclick="event.stopPropagation()"
        >
          Pobierz raport
        </button>

        <button
          type="button"
          class="btn btn-outline-danger btn-xs"
          @click="onDeleteButtonClicked(element)"
          onclick="event.stopPropagation()"
        >
          Usuń
        </button>
      </div>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
