<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ClusterMap from '../map/ClusterMap.vue';
import PasteOrderLocationCoords from './PasteOrderLocationCoords.vue';
import CoordsFromAddressWizard from '../addresses/CoordsFromAddressWizard.vue';
import ModalBase from '../utils/ModalBase.vue';
import ModalBody from '../utils/ModalBody.vue';
import { copyToClipboard, isNotEmptyArray } from '../../helpers/utilsHelper';
import { getDivIconForDemPointMarker } from '../../helpers/demPointMarkerHelper';
import ManualEnterEditLocation from './ManualEnterEditLocation.vue';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  REMOVE_LOCATION_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME,
  UPDATE_LOCATION_BUTTON_TYPE_NAME,
  GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { useMapLogic } from '../../composables/mapLogic';
import { showOnGoogleMaps } from '../../helpers/navigator';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { getLocationMarkerTooltip } from '../../helpers/locationsHelper';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryDemPoints = RepositoryFactory.get('demPoints');
const route = useRoute();
const router = useRouter();
const isLoading = ref(false);
const orderLocations = ref(null);
const { clusterMap } = useMapLogic();

// modal
const ModalComponentsMode = {
  None: 0,
  Paste: 1,
  CoordsFromAddress: 2,
  ManualEnter: 3,
  DemPointEdit: 4
};
const modalBase = ref(null);
const modalKey = ref(0);
const currentModalComponent = ref(ModalComponentsMode.None);
const currentLocationToUpdateId = ref(null);

const orderId = computed(() => parseInt(route.params?.id));
const locationsAvailable = computed(() => isNotEmptyArray(orderLocations.value));

const modalTitle = computed(() => {
  switch (currentModalComponent.value) {
    case ModalComponentsMode.Paste:
      return 'Wprowadź dane';
    case ModalComponentsMode.CoordsFromAddress:
      return 'Pozyskaj dane z adresu';
    case ModalComponentsMode.ManualEnter:
      return 'Wprowadź ręcznie';
    case ModalComponentsMode.DemPointEdit:
      return 'Edytuj lokalizację';
    default:
      return '';
  }
});

async function fetchData(setBoundsAfterRefresh = true) {
  isLoading.value = true;
  orderLocations.value = await RepositoryOrders.getOrderLocations(orderId.value);
  prepareMarkersForMap(setBoundsAfterRefresh);
  isLoading.value = false;
}

function prepareMarkersForMap(setBoundsAfterRefresh = true) {
  const markers = new Array();

  if (!locationsAvailable.value) return;

  orderLocations.value.forEach((location) => {
    if (location?.lat == null || location?.lon == null) return;

    const lat = location.lat;
    const lon = location.lon;
    const tooltip = getLocationMarkerTooltip(location);

    let marker = {
      lat: lat,
      lng: lon,
      popupCaption: tooltip,
      draggable: false,
      markerId: location.id,
      selected: false
    };

    marker = Object.assign(marker, getDivIconForDemPointMarker(location));

    if (!marker.divIcon) {
      console.warn(
        "OrdersLocations.prepareMarkersForMap(): Something went wrong, marker's divIcon object is null."
      );
    }

    const additionalShadows = getAdditionalShadowsForMarker(marker.divIcon, location);

    if (isNotEmptyArray(additionalShadows)) {
      additionalShadows.forEach((shadow) => {
        marker.divIcon.html += `<img src='/static/img/markerIcons/${shadow.url}.png' background-size: cover; style="position: absolute; left:${shadow.leftPx}px; top:${shadow.topPx}px; width: ${shadow.size[0]}px; height: ${shadow.size[1]}px;" />`;
      });
    }

    markers.push(marker);
  });

  clusterMap.value.initializeMap({ markersData: markers, setBoundsAfterRefresh });
}

function getAdditionalShadowsForMarker(divIcon, location) {
  const additionalShadows = [];

  const exclamationMarkShadow = getExclamationMarkShadowForMarker(divIcon, location);
  if (exclamationMarkShadow != null) additionalShadows.push(exclamationMarkShadow);

  return additionalShadows;
}

function getExclamationMarkShadowForMarker(divIcon, location) {
  if (!divIcon) return null;
  if (location?.warning_marker !== 1) return null;

  let shadowSize = [5.25, 15.375];

  return {
    url: 'exclamation_shadow',
    topPx: -0.5 * shadowSize[1],
    leftPx: divIcon.iconSize[0] + 1,
    size: shadowSize
  };
}

function onPasteCoordsButtonClicked() {
  showModal(ModalComponentsMode.Paste);
}

function onManualEnterButtonClicked() {
  showModal(ModalComponentsMode.ManualEnter);
}

function onGetFromTheAddressButtonClicked() {
  showModal(ModalComponentsMode.CoordsFromAddress);
}

function onEditDemPointButtonClicked(locationId) {
  currentLocationToUpdateId.value = locationId;
  showModal(ModalComponentsMode.DemPointEdit);
}

async function onVerifyLocationsButtonClicked() {
  const response = await RepositoryOrders.getLocationsVerificationInfo(orderId.value);

  let message = '';
  message += `<div class="text-left" style="font-size: 16">Zweryfikowano pomyślnie: <b>${response?.success}</b><br/>`;
  message += `Zweryfikowano z ostrzeżeniami: <b>${response?.warnings}</b><br/>`;

  const icon = response?.warnings > 0 ? 'warning' : 'info';

  Swal.fire({
    icon,
    title: 'Wynik weryfikacji',
    html: message
  });
}

function showModal(modalToShow) {
  currentModalComponent.value = modalToShow;
  modalKey.value++;
  modalBase.value.show();
}

function closeModalAndRefresh() {
  currentModalComponent.value = ModalComponentsMode.None;
  currentLocationToUpdateId.value = null;
  hideModal();
  fetchData();
}

function hideModal() {
  modalBase.value.hide();
}

function onMapClickableClicked(clickable) {
  let clickActionType = clickable.getAttribute('click_action_type');

  if (clickActionType == undefined) return;

  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');
  const tag = clickable.getAttribute('tag');
  const locationId = clickable.getAttribute('id_location');
  const orderId = clickable.getAttribute('id_order');
  const demPointId = clickable.getAttribute('id_demPoint');

  switch (clickActionType) {
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyToClipboard(lat + ',' + lon);
      }
      if (tag != undefined) {
        copyToClipboard(tag);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
    case UPDATE_LOCATION_BUTTON_TYPE_NAME:
      if (locationId != null) {
        onEditDemPointButtonClicked(parseInt(locationId));
      }
      break;
    case REMOVE_LOCATION_BUTTON_TYPE_NAME:
      if (locationId != null) {
        onDeleteLocationButtonClicked(parseInt(locationId));
      }
      break;
    case GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME:
      onShowOrderButtonClicked(orderId, demPointId);
      break;
  }
}

function onDeleteLocationButtonClicked(locationId) {
  Swal.fire(
    getSwalConfiguration('Usuwanie lokalizacji', 'Czy na pewno chcesz usunąć wybraną lokalizację?')
  ).then((result) => {
    if (result.value) deleteLocation(locationId);
  });
}

async function deleteLocation(locationId) {
  var response = await RepositoryDemPoints.deleteDemPoint(locationId);
  handleResponseWithMessages(response, 'Lokalizacja została usunięta.', () => fetchData(false));
}

function onShowOrderButtonClicked(orderId, demPointId) {
  if (orderId == null) return;

  let routeData = router.resolve({
    name: 'orderDetails',
    params: { id: orderId },
    query: { demPointId }
  });

  window.open(routeData.href, '_blank');
}

onMounted(() => {
  if (orderId.value) {
    fetchData();
  }
});
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="buttons-row">
        <div style="display: inline-flex; column-gap: 8px">
          <button class="btn btn-primary btn-xs" type="button" @click="onPasteCoordsButtonClicked">
            + Wklej współrzędne
          </button>

          <button class="btn btn-primary btn-xs" type="button" @click="onManualEnterButtonClicked">
            + Wprowadź ręcznie
          </button>

          <button
            class="btn btn-primary btn-xs"
            type="button"
            @click="onGetFromTheAddressButtonClicked"
          >
            + Pozyskaj z adresu
          </button>
        </div>

        <button
          class="btn btn-primary btn-xs"
          type="button"
          @click="onVerifyLocationsButtonClicked"
        >
          Weryfikacja punktów
        </button>
      </div>

      <div class="row mt-3">
        <ClusterMap
          ref="clusterMap"
          v-show="locationsAvailable"
          height="700px"
          :disableClusteringAtZoomOverride="19"
          :maxClusterRadiusOverride="1"
          @clickableClicked="onMapClickableClicked"
        ></ClusterMap>
        <div v-if="!locationsAvailable">
          <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
        </div>
      </div>
    </div>
  </div>

  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title>
        {{ modalTitle }}
      </template>

      <template v-slot:body>
        <PasteOrderLocationCoords
          v-if="currentModalComponent === ModalComponentsMode.Paste"
          :key="modalKey"
          :order-id="orderId"
          @dataSent="closeModalAndRefresh"
        />
        <CoordsFromAddressWizard
          v-if="currentModalComponent === ModalComponentsMode.CoordsFromAddress"
          :key="modalKey"
          :order-id="orderId"
          @allStepsFinished="hideModal"
          @refresh="fetchData"
        />
        <ManualEnterEditLocation
          v-if="
            currentModalComponent === ModalComponentsMode.ManualEnter ||
            currentModalComponent === ModalComponentsMode.DemPointEdit
          "
          :key="modalKey"
          :order-id="orderId"
          :locationId="currentLocationToUpdateId"
          @refresh="closeModalAndRefresh"
        />
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped>
.buttons-row {
  margin-inline: -15px;
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
}
</style>
