export const ADMIN_TYPE_ID = 1;
export const WORKER_TYPE_ID = 2;
export const DEV_TYPE_ID = 3;
export const COORDINATOR_TYPE_ID = 4;
export const DRIVER_TYPE_ID = 5;
export const TRANSPORT_MANAGER_TYPE_ID = 6;
export const SUBCONTRACTOR_TYPE_ID = 7;
export const RADO_COORDINATOR_TYPE_ID = 8;

// side menu
export const ACCESS_KEY_SIDE_MENU_ORDER_STATUSES = 'access_key_side_menu_order_statuses';
export const ACCESS_KEY_SIDE_MENU_GEODESY_PHASES = 'access_key_side_menu_geodesy_phases';
export const ACCESS_KEY_SIDE_MENU_USERS = 'access_key_side_menu_users';
export const ACCESS_KEY_SIDE_MENU_DICTIONARIES = 'access_key_side_menu_dictionaries';
export const ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS = 'access_key_side_menu_transport_orders';
export const ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS =
  'access_key_side_menu_transport_archived_orders';
export const ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS = 'access_key_side_menu_archived_orders';
export const ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY = 'access_key_side_menu_reports_history';
export const ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER = 'access_key_side_menu_actions_register';

// orders table
export const ACCESS_KEY_ORDERS_TABLE_NEW_ORDER_BUTTON = 'access_key_orders_table_new_order_button';
export const ACCESS_KEY_ORDERS_TABLE_EXPORT_BUTTON = 'access_key_orders_table_export_button';
export const ACCESS_KEY_ORDERS_TABLE_REQUEST_REPORT_BUTTON =
  'access_key_orders_table_request_report_button';
export const ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB =
  'access_key_orders_table_reports_history_tab';
export const ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB = 'access_key_orders_table_archived_tab';

// orders table filters
export const ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER =
  'access_key_orders_table_filters_order_status_filter';

// order details - tabs
export const ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB = 'access_key_order_details_contractors_tab';
export const ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB = 'access_key_order_details_transport_tab';
export const ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB =
  'access_key_order_details_acceptance_protocol_tab';
export const ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB =
  'access_key_order_details_geodetic_protocol_tab';
export const ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB = 'access_key_order_details_repository_tab';
export const ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB =
  'access_key_order_details_additional_info_tab';
export const ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB =
  'access_key_order_details_map_points_reports_tab';

// order details - buttons
export const ACCESS_KEY_ORDER_DETAILS_ARCHIVE_BUTTON = 'access_key_order_details_archive_button';
export const ACCESS_KEY_ORDER_DETAILS_EDIT_BUTTON = 'access_key_order_details_edit_button';
export const ACCESS_KEY_ORDER_DETAILS_DELETE_BUTTON = 'access_key_order_details_delete_button';

// transport order details - buttons
export const ACCESS_KEY_TRANSPORT_ORDER_DETAILS_DELETE_BUTTON =
  'access_key_transport_order_details_delete_button';

// actions
export const ACCESS_KEY_ACTION_ADD_BUTTON = 'access_key_action_add_button';
export const ACCESS_KEY_ACTION_EDIT_BUTTON = 'access_key_action_edit_button';
export const ACCESS_KEY_ACTION_REMOVE_BUTTON = 'access_key_action_remove_button';
export const ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON = 'access_key_action_show_hide_button';

// demPoints
export const ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON =
  'access_key_dempoint_activate_deactivate_button';

// adding workers
export const ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON = 'access_key_add_worker_to_order_button';

// settlement comment
export const ACCESS_KEY_SETTLEMENT_COMMENT = 'access_key_settlement_comment';

// actions register
export const ACCESS_KEY_ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON =
  'access_key_actions_register_table_request_report_button';

// user
export const ACCESS_KEY_UPDATE_USERS_2FA = 'access_key_update_users_2fa';

// other
export const ACCESS_KEY_USER_CREATE = 'access_key_user_create';
export const ACCESS_KEY_USER_UPDATE = 'access_key_user_update';
export const ACCESS_KEY_OTHER_USER_PASSWORD_UPDATE = 'access_key_other_user_password_update';

// dictionaries view columns
export const ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN = 'access_key_dictionaries_view_id_column';
