import {
  BACKUP_ICON,
  DELAY_STATUS_1_COLOR,
  DELAY_STATUS_2_COLOR,
  DELAY_STATUS_3_COLOR,
  ORDERS_MAP_STATUS_1_DELAY_1_ICON,
  ORDERS_MAP_STATUS_1_DELAY_2_ICON,
  ORDERS_MAP_STATUS_1_DELAY_3_ICON,
  ORDERS_MAP_STATUS_2_DISASSEMBLY_3_ICON,
  ORDERS_MAP_STATUS_2_DISASSEMBLY_4_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_1_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_2_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_3_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_4_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_5_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_6_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_7_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_8_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_9_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_10_ICON,
  ORDERS_MAP_STATUS_3_INTERRUPTION_11_ICON,
  ORDERS_MAP_STATUS_4_ICON,
  ORDERS_MAP_STATUS_5_ICON,
  ORDERS_MAP_STATUS_6_ICON,
  ORDERS_MAP_STATUS_7_ICON,
  ORDERS_MAP_STATUS_8_ICON,
  ORDERS_MAP_STATUS_9_ICON,
  ORDERS_MAP_STATUS_10_ICON,
  ORDERS_MAP_STATUS_11_ICON,
  PRIORITY_ICON,
  RED_PRIORITY_ICON
} from '../data/constants/ordersMapMarkersIconsConstants';
import { isNotEmptyArray } from './utilsHelper';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../data/constants/buttonsNamesConstants';
import {
  CANCELLED_ORDER_STATUS_ID,
  DISMANTLING_INTERRUPTED_ORDER_STATUS_ID,
  OPL_VERIFICATION_ORDER_STATUS_ID,
  ORDER_INITIATIVE_WZOS_TYPE_ID,
  RADO_VERIFICATION_ORDER_STATUS_ID
} from '../data/constants/appConstants';

const GEODESY_ORDER_TYPE_ID = 3;

export function getMarkersForOrdersMap(ordersObject) {
  if (!isNotEmptyArray(ordersObject?.data)) return { markers: [], failedToGetMarkerOrders: [] };

  const markers = new Array();
  const failedToGetMarkerOrders = new Array();
  const orders = ordersObject.data;

  orders.forEach((order) => {
    const marker = getMarkerForOrdersMap(order);

    if (marker) {
      markers.push(marker);
    } else {
      failedToGetMarkerOrders.push(order);
    }
  });

  return { markers, failedToGetMarkerOrders };
}

function getMarkerForOrdersMap(order) {
  let lat = order?.first_dem_point?.lat ?? order?.first_address?.lat;
  let lon = order?.first_dem_point?.lon ?? order?.first_address?.lon;

  if (lat == null || lon == null) return null;

  const coordsPopupCaption =
    `${lat}, ${lon} ` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${order?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${order?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;
  let orderInfoPopupCaption = '<b>' + order.order_type.name + '</b><br/>' + order.order_number;

  let marker = {
    lat: lat,
    lng: lon,
    popupCaption: coordsPopupCaption + '<br/>' + orderInfoPopupCaption,
    draggable: false,
    markerId: order.id,
    orderId: order.id,
    selected: false,
    selectable: true
  };

  marker = Object.assign(marker, getIconForMarker(order));
  marker = Object.assign(marker, getShadowForMarker(marker, order));
  const additionalShadows = getAdditionalShadowsForMarker(marker, order);

  let divIcon = null;

  if (marker.divIcon != null) {
    divIcon = marker.divIcon;
  } else {
    divIcon = {
      className: '',
      iconSize: marker.iconSize,
      iconAnchor: marker.iconAnchor,
      html: `<img style='position: absolute; top: 0; left: 0; width: ${marker.iconSize[0]}px; height: ${marker.iconSize[1]}px;' src='/static/img/markerIcons/${marker.icon}.png' background-size: cover;"/>`
    };
  }

  if (marker.shadowUrl != null) {
    let shadowOffsetX = marker.iconAnchor[0] - marker.shadowAnchor[0];
    let shadowOffsetY = marker.iconAnchor[1] - marker.shadowAnchor[1];
    divIcon.html += `<img src='/static/img/markerIcons/${marker.shadowUrl}.png' background-size: cover; style="position: absolute; left:${shadowOffsetX}px; top:${shadowOffsetY}px; width: ${marker.shadowSize[0]}px; height: ${marker.shadowSize[1]}px;" />`;
  }

  if (isNotEmptyArray(additionalShadows)) {
    additionalShadows.forEach((shadow) => {
      divIcon.html += `<img src='/static/img/markerIcons/${shadow.url}.png' background-size: cover; style="position: absolute; left:${shadow.leftPx}px; top:${shadow.topPx}px; width: ${shadow.size[0]}px; height: ${shadow.size[1]}px;" />`;
    });
  }

  if (marker.add_default_osd_shadow === true) {
    let markerIconName = 'OSD';
    let width = 26;
    let height = 10;
    let leftPx = marker.iconSize[0] / 2 - width / 2;
    let topPx = marker.iconSize[1] + 1 + (marker.shadowUrl != null ? marker.shadowSize[1] + 2 : 0);
    divIcon.html += `<img src='/static/img/markerIcons/${markerIconName}.png' background-size: cover; style="position: absolute; left:${leftPx}px; top:${topPx}px; width: ${width}px; height: ${height}px;" />`;
  }

  marker = Object.assign(marker, { divIcon });

  if (marker.icon == null) {
    marker = Object.assign(marker, BACKUP_ICON);
  }

  return marker?.icon ? marker : null;
}

function getIconForMarker(order) {
  if (order.order_type?.id === GEODESY_ORDER_TYPE_ID)
    return getIconByDelayStatus(order.delay_status);

  if (order.last_worker == null) {
    if (order.id_order_status === OPL_VERIFICATION_ORDER_STATUS_ID) return ORDERS_MAP_STATUS_4_ICON;
    if (order.id_order_status === RADO_VERIFICATION_ORDER_STATUS_ID)
      return ORDERS_MAP_STATUS_11_ICON;
    if (order.id_order_status === CANCELLED_ORDER_STATUS_ID) return ORDERS_MAP_STATUS_5_ICON;
    if (order.priority) return RED_PRIORITY_ICON;
    if (order.id_order_status === DISMANTLING_INTERRUPTED_ORDER_STATUS_ID)
      return getIconByInterruptionStatus(order.id_order_interruption_status);
    if (order.order_range_icon != null)
      return getIconByOrderRangeAndDelayIcon(order.order_range_icon, order.delay_status);
  }

  switch (order.id_order_status) {
    case 1:
      return getIconByDelayStatus(order.delay_status);
    case 2:
      if (order.last_worker) {
        return order.notes_and_failures > 0
          ? PRIORITY_ICON
          : getIconByDisassemblyStatus(
              order.last_worker.id_disassembly_status,
              order.last_worker.color
            );
      } else {
        return getIconByDelayStatus(order.delay_status);
      }
    case 3:
      return getIconByInterruptionStatus(order.id_order_interruption_status);
    case 4:
      return ORDERS_MAP_STATUS_4_ICON;
    case 5:
      return ORDERS_MAP_STATUS_5_ICON;
    case 6:
      return ORDERS_MAP_STATUS_6_ICON;
    case 7:
      return ORDERS_MAP_STATUS_7_ICON;
    case 8:
      return ORDERS_MAP_STATUS_8_ICON;
    case 9:
      return ORDERS_MAP_STATUS_9_ICON;
    case 10:
      return ORDERS_MAP_STATUS_10_ICON;
    case 11:
      return ORDERS_MAP_STATUS_11_ICON;
    default:
      console.warn('orderMarkersHelper.getIconForMarker(): Some cases not handled.');
      return null;
  }
}

function getIconByDelayStatus(delayStatus) {
  switch (delayStatus) {
    case 1:
      return ORDERS_MAP_STATUS_1_DELAY_1_ICON;
    case 2:
      return ORDERS_MAP_STATUS_1_DELAY_2_ICON;
    case 3:
      return ORDERS_MAP_STATUS_1_DELAY_3_ICON;
    default:
      console.warn('orderMarkersHelper.getIconByDelayStatus(): Some cases not handled.');
      return null;
  }
}

function getIconByDisassemblyStatus(disassemblyStatusId, color) {
  switch (disassemblyStatusId) {
    case 1:
      return getIconForStatus2Disassembly1(color);
    case 2:
      return getIconForStatus2Disassembly2(color);
    case 3:
      return ORDERS_MAP_STATUS_2_DISASSEMBLY_3_ICON;
    case 4:
      return ORDERS_MAP_STATUS_2_DISASSEMBLY_4_ICON;
    default:
      console.warn('orderMarkersHelper.getIconByDisassemblyStatus(): Some cases not handled.');
      return null;
  }
}

function getIconByInterruptionStatus(interruptionStatusId) {
  switch (interruptionStatusId) {
    case 1:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_1_ICON;
    case 2:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_2_ICON;
    case 3:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_3_ICON;
    case 4:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_4_ICON;
    case 5:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_5_ICON;
    case 6:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_6_ICON;
    case 7:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_7_ICON;
    case 8:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_8_ICON;
    case 9:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_9_ICON;
    case 10:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_10_ICON;
    case 11:
      return ORDERS_MAP_STATUS_3_INTERRUPTION_11_ICON;
    default:
      console.warn('orderMarkersHelper.getIconByInterruptionStatus(): Some cases not handled.');
      return null;
  }
}

function getIconForStatus2Disassembly1(workerColor) {
  const borderColor = workerColor ? 'black' : 'white';
  const fillColor = workerColor ?? 'black';

  return {
    icon: null,
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    divIcon: {
      className: '',
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      html: `
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8" stroke="${borderColor}" stroke-width="0.5" fill="${fillColor}" />
      </svg>
      `
    }
  };
}

function getIconForStatus2Disassembly2(workerColor) {
  const borderColor = workerColor ? 'black' : 'white';
  const fillColor = workerColor ?? 'black';

  return {
    icon: null,
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    divIcon: {
      className: '',
      iconSize: [20, 20],
      iconAnchor: [10, 10],
      html: `
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8" stroke="${borderColor}" stroke-width="2" fill="${fillColor}" />
      </svg>
      `
    }
  };
}

function getIconByOrderRangeAndDelayIcon(orderRangeIcon, delayStatus) {
  switch (orderRangeIcon) {
    case 'circle':
      return {
        icon: `circle_${getColorByDelayStatus(delayStatus)}`,
        iconSize: [34, 32],
        iconAnchor: [17, 16]
      };
    case 'square':
      return {
        icon: `square_${getColorByDelayStatus(delayStatus)}`,
        iconSize: [20, 19],
        iconAnchor: [10, 9.5]
      };
    case 't_letter':
      return {
        icon: `t_letter_${getColorByDelayStatus(delayStatus)}`,
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    case 'star':
      return {
        icon: `star_marker_${getColorByDelayStatus(delayStatus)}`,
        iconSize: [24, 24],
        iconAnchor: [12, 12]
      };
    case 'default':
      return {
        icon: `${getColorByDelayStatus(delayStatus)}_marker`,
        iconSize: [16, 26],
        iconAnchor: [8, 26]
      };
    case 'default_osd':
      return {
        icon: `${getColorByDelayStatus(delayStatus)}_marker`,
        iconSize: [16, 26],
        iconAnchor: [8, 26],
        add_default_osd_shadow: true
      };
    case 'plus':
      return {
        icon: `opl_${getColorByDelayStatus(delayStatus)}`,
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      };
    case 'ln_round':
      return {
        icon: `ln_round`,
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      };
    case 'car_red_round':
      return {
        icon: `car_red_round`,
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      };
    case 'qmark_round':
      return {
        icon: `red_question_mark`,
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      };
    case 'car_black_round':
      return {
        icon: `car_black_round`,
        iconSize: [30, 30],
        iconAnchor: [15, 15]
      };
    default:
      console.warn(
        'orderMarkersHelper.getIconByOrderRangeAndDelayIcon(): Some cases not handled. -> ' +
          `${orderRangeIcon}`
      );
      return null;
  }
}

function getColorByDelayStatus(delayStatus) {
  switch (delayStatus) {
    case 1:
      return DELAY_STATUS_1_COLOR;
    case 2:
      return DELAY_STATUS_2_COLOR;
    case 3:
      return DELAY_STATUS_3_COLOR;
    default:
      console.warn('orderMarkersHelper.getColorByDelayStatus(): Some cases not handled.');
      return null;
  }
}

function getShadowForMarker(marker, order) {
  if (!isNotEmptyArray(marker?.iconSize) || !isNotEmptyArray(marker?.iconAnchor)) return null;
  if (!order?.subcontractor) return null;

  const yOffset = marker.iconSize[1] - marker.iconAnchor[1] + 2;

  return {
    shadowUrl: 'square_blue',
    shadowSize: [20, 5],
    shadowAnchor: [10, 0 - yOffset]
  };
}

function getAdditionalShadowsForMarker(marker, order) {
  const additionalShadows = [];

  const exclamationMarkShadow = getExclamationMarkShadowForMarker(marker, order);
  if (exclamationMarkShadow != null) additionalShadows.push(exclamationMarkShadow);

  const orderInitiativeShadow = getOrderInitiativeShadowForMarker(marker, order);
  if (orderInitiativeShadow != null) additionalShadows.push(orderInitiativeShadow);

  return additionalShadows;
}

function getExclamationMarkShadowForMarker(marker, order) {
  if (order.order_type?.id === GEODESY_ORDER_TYPE_ID) return null;
  if (order.priority && order.last_worker == null) return null;
  if (order.last_worker == null && order.order_range_icon != null) return null;

  if (order.priority && order.id_order_status === 2 && order.last_worker) {
    let shadowSize = [5.25, 15.375];

    return {
      url: 'exclamation_shadow',
      topPx: -0.5 * shadowSize[1],
      leftPx: marker.iconSize[0],
      size: shadowSize
    };
  }

  return null;
}

function getOrderInitiativeShadowForMarker(marker, order) {
  if (order.id_order_initiative !== ORDER_INITIATIVE_WZOS_TYPE_ID) return null;

  let shadowSize = [9.333, 7];

  return {
    url: 'wzos_icon',
    topPx: -0.5 * shadowSize[1],
    leftPx: 0 - shadowSize[0] + 2,
    size: shadowSize
  };
}
