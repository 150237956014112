import {
  REMOVE_ACTION_BUTTON_TYPE_NAME,
  HIDE_ACTION_BUTTON_TYPE_NAME,
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME,
  UPDATE_ACTION_BUTTON_TYPE_NAME,
  ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../data/constants/buttonsNamesConstants.js';

import {
  ACTION_FROM_DEM_POINT_MARKER_TYPE_ID,
  ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID,
  DEM_POINT_MARKER_TYPE_ID,
  FAILURE_REASON_ACTIVE_POLE_ID,
  FAILURE_REASON_OWNER_DISAGREEMENT_ID,
  SINGLE_DEM_POINT_TYPE_ID,
  START_ORDER_ACTION_POPUP_CAPTION,
  STOP_ORDER_ACTION_POPUP_CAPTION
} from '../data/constants/appConstants.js';

import { isNotEmptyArray, pushSafe } from './utilsHelper';
import { getDivIconForDemPointMarker } from './demPointMarkerHelper';
import { COORDS_DIFFERENCE_METERS_WARNING_THRESHOLD } from '../data/projectConfig.js';
import {
  ACCESS_KEY_ACTION_EDIT_BUTTON,
  ACCESS_KEY_ACTION_REMOVE_BUTTON,
  ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON,
  ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON
} from '../data/constants/authConstants.js';

export function getDemPointsForMapMarkers(
  demPoints,
  disabledActionTypesIds,
  disabledFailureReasonsIds,
  disabledAdditionalDismantlingSourcesIds,
  disabledUserOrdersIds,
  showOnlyLastActionsFilterEnabled,
  addTooltip = true,
  selectingBlocked = false,
  markersClassName = '',
  showActionIfHasAny = true,
  authStore = null,
  hideOperationalButtons = false,
  optionals = null
) {
  if (!isNotEmptyArray(demPoints)) return [];

  const markers = new Array();

  demPoints.forEach((demPoint) => {
    let marker = null;
    let actionTypeId = null;
    let userOrderId = null;
    const additionalButton =
      authStore.canAccess(ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON) && !hideOperationalButtons
        ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs mt-1' id='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}:${
            demPoint?.id
          }' id_demPoint='${
            demPoint?.id
          }' click_action_type='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}' demPoint_add_action_allowed='${
            demPoint.add_action_allowed
          }'>${demPoint.add_action_allowed == 1 ? 'Dezaktywuj' : 'Aktywuj'}</button>`
        : '';

    if (isNotEmptyArray(demPoint?.actions) && showActionIfHasAny) {
      let actionToDisplay = demPoint.actions.find(
        (action) =>
          action.map_display === 1 &&
          !disabledActionTypesIds.includes(action.action_type?.id) &&
          !disabledUserOrdersIds.includes(action?.id_user_order) &&
          (action.failure_reason == null ||
            !disabledFailureReasonsIds.includes(action.failure_reason.id)) &&
          (action.extra_dem_source == null ||
            !disabledAdditionalDismantlingSourcesIds.includes(action.extra_dem_source.id))
      );

      if (
        actionToDisplay != null &&
        showOnlyLastActionsFilterEnabled &&
        !isMostRecentAction(demPoint.actions, actionToDisplay)
      ) {
        actionToDisplay = null;
      }

      let isOSD = demPoint?.dem_elem_type?.id === 6;

      marker = actionToDisplay
        ? getConstructedMarkerForAction(
            actionToDisplay,
            isOSD,
            demPoint.lat,
            demPoint.lon,
            addTooltip,
            additionalButton,
            markersClassName,
            authStore,
            demPoint,
            hideOperationalButtons,
            optionals?.selectableActionsOverride
          )
        : null;
      actionTypeId = actionToDisplay?.action_type?.id ?? null;
      userOrderId = actionToDisplay?.id_user_order ?? null;
    } else {
      marker = getConstructedMarkerForDemPoint(
        demPoint,
        addTooltip,
        additionalButton,
        selectingBlocked,
        markersClassName,
        optionals?.selectableDemPointsOverride
      );
    }

    if (
      marker &&
      !disabledActionTypesIds.includes(actionTypeId) &&
      !disabledUserOrdersIds.includes(userOrderId)
    ) {
      markers.push(marker);
    }
  });

  return markers;
}

function isMostRecentAction(demPointActions, action) {
  if (!isNotEmptyArray(demPointActions)) return false;
  if (action == null) return false;
  if (demPointActions.length === 1) return true;

  const actionDate = new Date(action.geotimestamp.date);
  let isMostRecent = true;

  for (let i = 0; i < demPointActions.length; i++) {
    const currentAction = demPointActions[i];
    const currentDate = new Date(currentAction.geotimestamp.date);

    if (currentDate > actionDate) {
      isMostRecent = false;
      break;
    }
  }

  return isMostRecent;
}

function getConstructedMarkerForDemPoint(
  demPoint,
  addTooltip,
  additionalButton,
  selectingBlocked = false,
  markersClassName = '',
  selectableOverride = null
) {
  const lat = demPoint.lat;
  const lon = demPoint.lon;

  let tooltip = null;

  if (addTooltip) {
    let coordsCaption = `${lat}, ${lon} <button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${demPoint?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
    coordsCaption += `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${demPoint?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;
    const tag = demPoint?.oznaczenie ? '<br/><b>' + demPoint?.oznaczenie + '</b>' : '';
    const copyTagButton =
      tag === ''
        ? ''
        : `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${location?.id}:tag' tag='${demPoint.oznaczenie}'click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
    const tagSection = tag + copyTagButton;
    let noteCaption = demPoint.note ? `<br/>Uwagi: <i>${demPoint.note}</i>` : '';
    let additionalButtonCaption = additionalButton
      ? `<br/><div class='mt-1'>` + additionalButton + '</div>'
      : '';

    tooltip = coordsCaption + tagSection + noteCaption + additionalButtonCaption;
  }

  let marker = {
    lat: lat,
    lng: lon,
    popupCaption: tooltip,
    draggable: false,
    selected: false,
    selectable:
      selectableOverride != null
        ? selectableOverride
        : demPoint?.dem_point_type?.id === SINGLE_DEM_POINT_TYPE_ID && !selectingBlocked,
    markerId: demPoint.id,
    demPointTag: demPoint.oznaczenie,
    markerType: DEM_POINT_MARKER_TYPE_ID,
    add_action_allowed: demPoint.add_action_allowed,
    demPointTypeId: demPoint.dem_point_type.id
  };

  marker = Object.assign(marker, getDivIconForDemPointMarker(demPoint, markersClassName));

  if (demPoint.osd_poles > 0) {
    const width = marker.divIcon?.iconSize?.[0] ?? marker.iconSize[0];
    const height = marker.divIcon?.iconSize?.[1] ?? marker.iconSize[1];

    marker.shadows = pushSafe(marker.shadows, {
      url: 'OSD_underline',
      size: [26, 5],
      topPx: height + 2,
      leftPx: width / 2 - 13
    });
  }

  return marker;
}

function getConstructedMarkerForAction(
  action,
  isOSD,
  lat,
  lon,
  addTooltip,
  additionalButton,
  markerClassName = '',
  authStore = null,
  demPoint,
  hideOperationalButtons = false,
  selectableOverride = null
) {
  if (!action?.map_display) return null;

  let actionNote = action.note ? `Notatka: <i>${action.note}</i><br>` : '';
  let demPointNote = demPoint?.note ? `Uwagi: <i>${demPoint.note}</i><br/>` : '';

  const diff = action?.geotimestamp?.difference;
  const coordsDifferenceText =
    diff && diff > COORDS_DIFFERENCE_METERS_WARNING_THRESHOLD
      ? `<br/><span style='color:#f8ac59'>${getFormattedCoordsDifference(
          diff
        )} od urządzenia</span>`
      : '';

  const additionalButtonCaption = additionalButton ?? '';
  const actionTypeDependentInfoText = getActionTypeDependentInfoText(action);

  const editButton =
    authStore.canAccess(ACCESS_KEY_ACTION_EDIT_BUTTON) && !hideOperationalButtons
      ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs mr-1 mt-1' id='${UPDATE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${UPDATE_ACTION_BUTTON_TYPE_NAME}'>Edytuj</button>`
      : '';
  const hideButton =
    authStore.canAccess(ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON) && !hideOperationalButtons
      ? `<button clickable type='button' class='btn btn-outline btn-warning btn-xs mr-1 mt-1' id='${HIDE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${HIDE_ACTION_BUTTON_TYPE_NAME}'>Ukryj</button>`
      : '';
  const removeButton =
    authStore.canAccess(ACCESS_KEY_ACTION_REMOVE_BUTTON) && !hideOperationalButtons
      ? `<button clickable type='button' class='btn btn-outline btn-danger btn-xs mr-1 mt-1' id='${REMOVE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${REMOVE_ACTION_BUTTON_TYPE_NAME}'>Usuń</button>`
      : '';

  const optionalActionFailureText = getOptionalActionFailureInfoText(action);
  const optionalActionAdditionalDismantlingSourceText =
    getOptionalActionAdditionalDismantlingSourceText(action);

  const groupCountInfoText =
    action.groupCount != null && action.groupCount > 0
      ? `<br/><b>Akcji w grupie: ${action.groupCount}</b>`
      : '';

  const workerName = action.worker?.firstName + ' ' + action.worker?.lastName;
  const actionCreatedDate = `Dodano: ${action.geotimestamp?.date}`;

  const demPointTag =
    demPoint?.oznaczenie != null ? `<br/>Oznaczenie: ${demPoint?.oznaczenie}` : '';

  const copyTagButton =
    demPointTag === ''
      ? ''
      : `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${action?.id}:tag' tag='${demPoint.oznaczenie}'click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  const tagSection = demPointTag + copyTagButton;

  let markerPopupCaption = addTooltip
    ? `<b>${action.action_type.name}</b><br>` +
      optionalActionFailureText +
      optionalActionAdditionalDismantlingSourceText +
      `${workerName}<br/>` +
      `${actionNote}` +
      `${demPointNote}` +
      `${lat}, ${lon} ` +
      `<button clickable type='button' class="btn btn-white btn-xs ml-1 mb-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
      `<button clickable type='button' class="btn btn-white btn-xs ml-1 mb-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>` +
      coordsDifferenceText +
      actionTypeDependentInfoText +
      tagSection +
      groupCountInfoText +
      `<br/>${actionCreatedDate}` +
      `<br>` +
      `<button clickable type='button' class='btn btn-outline btn-info btn-xs mr-1 mt-1' id='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' action_type_name='${action?.action_type?.name}' click_action_type='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}'>Galeria</button>` +
      editButton +
      hideButton +
      removeButton +
      additionalButtonCaption
    : null;

  let marker = {
    markerId: 'action_' + action.id,
    lat,
    lng: lon,
    popupCaption: markerPopupCaption,
    draggable: false,
    icon: undefined,
    iconSize: undefined,
    iconAnchor: undefined,
    selectable: selectableOverride != null ? selectableOverride : false,
    className: markerClassName,
    groupId: action.id_group,
    actionId: action.id,
    demPointId: demPoint != null ? demPoint.id : undefined,
    markerType:
      demPoint == null
        ? ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID
        : ACTION_FROM_DEM_POINT_MARKER_TYPE_ID,
    add_action_allowed:
      demPoint?.add_action_allowed != null ? demPoint?.add_action_allowed : undefined,
    demPointTypeId: demPoint?.dem_point_type?.id != null ? demPoint.dem_point_type.id : undefined
  };

  assignActionMarkerIcon(
    marker,
    action.action_type.id,
    isOSD,
    action?.failure_reason?.id,
    demPoint == null
  );

  if (demPoint?.osd_poles != null && demPoint.osd_poles > 0) {
    marker.shadows = pushSafe(marker.shadows, {
      url: 'OSD_underline',
      size: [26, 5],
      topPx: marker.iconSize[1] + 2,
      leftPx: marker.iconSize[0] / 2 - 13
    });
  }

  if (demPoint?.add_action_allowed === 0) {
    marker.shadows = pushSafe(marker.shadows, {
      url: 'small_black_x',
      size: [9.5, 9],
      topPx: 0,
      leftPx: marker.iconSize[0] - 2
    });
  }

  return marker;
}

function getOptionalActionFailureInfoText(action) {
  const optionalDismantlingFailureReason = action.failure_reason
    ? `Powód niepowodzenia: ${action.failure_reason.name}<br/>`
    : '';

  const optionalDismantlingFailureCableType =
    action.failure_reason != null &&
    action.failure_reason.id === FAILURE_REASON_ACTIVE_POLE_ID &&
    action.cable_type_for_failure
      ? `Typ przewodu: ${action.cable_type_for_failure.name}<br/>`
      : '';

  const optionalDismantlingFailureDeclarationOfDisagreement =
    action.failure_reason != null &&
    action.failure_reason.id === FAILURE_REASON_OWNER_DISAGREEMENT_ID
      ? `Spisano oświadczenie: ${action.declaration_of_disagreement === 1 ? 'Tak' : 'Nie'}<br/>`
      : '';

  return (
    optionalDismantlingFailureReason +
    optionalDismantlingFailureCableType +
    optionalDismantlingFailureDeclarationOfDisagreement
  );
}

function getOptionalActionAdditionalDismantlingSourceText(action) {
  if (action.extra_dem_source == null) return '';

  return `Źródło: ${action.extra_dem_source.name}<br/>`;
}

function getActionTypeDependentInfoText(action) {
  if (action.osd_detail != null) {
    let result = '<br/><b>Szczegóły OSD</b>';

    result +=
      action.osd_detail?.osd_release != null
        ? `<br/>Uwolnienie: ${action.osd_detail.osd_release.name}`
        : '';

    result +=
      action.osd_detail?.osd_element_link != null
        ? `<br/>Powiązanie: ${action.osd_detail.osd_element_link.name}`
        : '';

    result +=
      action.osd_detail?.optical_cable != null
        ? `<br/>Kabel optyczny: ${action.osd_detail.optical_cable}`
        : '';

    result +=
      action.osd_detail?.copper_cable != null
        ? `<br/>Kabel miedziany: ${action.osd_detail.copper_cable}`
        : '';

    if (isNotEmptyArray(action.osd_detail?.osd_poles)) {
      result += '<br/>Powiązane słupy:';

      action.osd_detail.osd_poles.forEach((pole) => {
        result += `<br/>- ${pole.oznaczenie}`;
      });
    }

    return result;
  }

  if (isNotEmptyArray(action.storage_details)) {
    let result = '<br/><b>Szczegóły miejsca składowania</b>';

    action.storage_details.forEach((element) => {
      result += `<br/>${element.name}: ${element.number}`;
    });

    return result;
  }

  return '';
}

function assignActionMarkerIcon(
  marker,
  actionTypeId,
  isOSD,
  dismantlingFailureReasonId,
  directlyOnMap
) {
  marker.iconSize = [33, 48];
  marker.iconAnchor = [16, 48];

  switch (actionTypeId) {
    case 1:
      marker.icon = isOSD ? 'action_marker_mechanic_osd' : 'action_marker_mechanic';
      break;
    case 2:
      marker.icon = isOSD ? 'action_marker_fist_osd' : 'action_marker_fist';
      break;
    case 3:
      if (dismantlingFailureReasonId != null) {
        marker.icon = getActionMarkerIconByDismantlingFailureReason(dismantlingFailureReasonId);
        marker.iconSize = [30, 30];
        marker.iconAnchor = [15, 15];
      } else {
        marker.icon = 'action_marker_red_x';
      }
      break;
    case 4:
      marker.icon = 'action_marker_transport';
      break;
    case 5:
      marker.icon = isOSD ? 'action_marker_mechanic_plus_osd' : 'action_marker_mechanic_plus';
      break;
    case 6:
      marker.icon = isOSD ? 'action_marker_fist_plus_osd' : 'action_marker_fist_plus';
      break;
    case 7:
      marker.icon = directlyOnMap ? 'action_marker_pencil_plus' : 'action_marker_pencil';
      break;
    case 8:
      marker.icon = 'action_marker_cube';
      break;
    case 9:
      marker.icon = 'confirmed_for_disassembly';
      marker.iconSize = [30, 30];
      marker.iconAnchor = [15, 15];
      break;
  }
}

function getActionMarkerIconByDismantlingFailureReason(dismantlingFailureReasonId) {
  switch (dismantlingFailureReasonId) {
    case 1:
      return 'POR_green';
    case 2:
      return 'grain';
    case 3:
      return 'water';
    case 4:
      return 'phone_crossed_out';
    case 5:
      return 'tree_v2';
    case 6:
      return 'active_pole';
    case 7:
      return 'hammer';
    case 8:
      return 'red_question_mark';
    case 9:
      return 'no_pole_marker';
    case 10:
      return 'declaration_of_disagreement';
    case 11:
      return 'rechecking';
    case 12:
      return 'third_party_operator';
  }
}

export function getActionsWithNoDemPointForMapMarkers(
  actionsWithNoDemPoint,
  disabledActionTypesIds,
  disabledFailureReasonsIds,
  disabledAdditionalDismantlingSourcesIds,
  disabledUserOrdersIds,
  addTooltip = true,
  markersClassName = '',
  authStore = null,
  hideOperationalButtons = false,
  optionals = null
) {
  if (!isNotEmptyArray(actionsWithNoDemPoint)) return [];
  const markers = new Array();

  actionsWithNoDemPoint.forEach((action) => {
    let marker = getConstructedMarkerForAction(
      action,
      action.osd_detail != null,
      action.geotimestamp.lat,
      action.geotimestamp.lon,
      addTooltip,
      null,
      markersClassName,
      authStore,
      null,
      hideOperationalButtons,
      optionals?.selectableActionsOverride
    );

    if (
      marker &&
      !disabledActionTypesIds.includes(action.action_type.id) &&
      !disabledUserOrdersIds.includes(action?.id_user_order) &&
      (action.failure_reason == null ||
        !disabledFailureReasonsIds.includes(action.failure_reason.id)) &&
      (action.extra_dem_source == null ||
        !disabledAdditionalDismantlingSourcesIds.includes(action.extra_dem_source.id))
    ) {
      markers.push(marker);
    }
  });

  return markers;
}

export function getWorkersStartStopMarkers(workersStartStopData, enabledUserOrdersStartStopsIds) {
  if (!isNotEmptyArray(workersStartStopData)) return [];

  const markers = new Array();

  workersStartStopData.forEach((workerStartStopData) => {
    if (!enabledUserOrdersStartStopsIds.includes(workerStartStopData.id)) return;
    let startStopMarkers = getStartStopMarkersDataForOrderWorker(workerStartStopData);
    markers.push(...startStopMarkers);
  });

  return markers;
}

function getStartStopMarkersDataForOrderWorker(orderWorkerData) {
  let result = [];

  if (orderWorkerData.start) {
    let startMarker = {
      markerId: 'start',
      lat: orderWorkerData.start.lat,
      lng: orderWorkerData.start.lon,
      popupCaption: START_ORDER_ACTION_POPUP_CAPTION,
      draggable: false,
      icon: 'start_marker',
      iconSize: [25, 38],
      iconAnchor: [6, 38],
      selectable: false
    };

    result.push(startMarker);
  }

  if (orderWorkerData.stop) {
    let stopMarker = {
      markerId: 'stop',
      lat: orderWorkerData.stop.lat,
      lng: orderWorkerData.stop.lon,
      popupCaption: STOP_ORDER_ACTION_POPUP_CAPTION,
      draggable: false,
      icon: 'finish_marker',
      iconSize: [25, 38],
      iconAnchor: [6, 38],
      selectable: false
    };

    result.push(stopMarker);
  }

  return result;
}

function getFormattedCoordsDifference(difference) {
  return difference > 1000 ? (difference / 1000).toFixed(1) + ' km' : difference + ' m';
}

export function addGroupsCountInfoToActions(demPoints, actionsWithNoDemPoint) {
  const actionGroupsDictionary = {};

  // demPoints added to groups dictionary
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null) {
            if (Object.prototype.hasOwnProperty.call(actionGroupsDictionary, action.id_group)) {
              actionGroupsDictionary[action.id_group]++;
            } else {
              actionGroupsDictionary[action.id_group] = 1;
            }
          }
        });
      }
    });
  }

  // actions with no demPoint added to groups dictionary
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null) {
        if (Object.prototype.hasOwnProperty.call(actionGroupsDictionary, action.id_group)) {
          actionGroupsDictionary[action.id_group]++;
        } else {
          actionGroupsDictionary[action.id_group] = 1;
        }
      }
    });
  }

  // based on actionGroupsDictionary, added groupCount to each action from demPoints
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null) {
            action.groupCount = actionGroupsDictionary[action.id_group];
          }
        });
      }
    });
  }

  // based on actionGroupsDictionary, added groupCount to each action from actions with no dem points array
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null) {
        action.groupCount = actionGroupsDictionary[action.id_group];
      }
    });
  }
}

export function getActionGroupMembers(groupId, demPoints, actionsWithNoDemPoint) {
  const members = [];

  // demPoints
  if (isNotEmptyArray(demPoints)) {
    demPoints.forEach((demPoint) => {
      if (isNotEmptyArray(demPoint?.actions)) {
        demPoint.actions.forEach((action) => {
          if (action.id_group != null && action.id_group === groupId) {
            action.dem_point = {
              id: demPoint.id,
              lat: demPoint.lat,
              lon: demPoint.lon
            };
            members.push(action);
          }
        });
      }
    });
  }

  // actions with no demPoint
  if (isNotEmptyArray(actionsWithNoDemPoint)) {
    actionsWithNoDemPoint.forEach((action) => {
      if (action.id_group != null && action.id_group === groupId) {
        members.push(action);
      }
    });
  }

  return members;
}
