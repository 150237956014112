<script setup>
import { computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { createToaster } from '@meforma/vue-toaster';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import {
  ACTION_FROM_DEM_POINT_MARKER_TYPE_ID,
  ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID,
  DEM_POINT_MARKER_TYPE_ID
} from '../../data/constants/appConstants';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';

const RepositoryDemPoints = RepositoryFactory.get('demPoints');

const props = defineProps({
  orderId: Number,
  selectedMarkers: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['pointsDeactivated']);

const demPointsCount = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return 0;
  return props.selectedMarkers.filter((x) => x.data.markerType === DEM_POINT_MARKER_TYPE_ID).length;
});

const demPointsDeactivatedCount = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return 0;
  return props.selectedMarkers.filter(
    (x) => x.data.markerType === DEM_POINT_MARKER_TYPE_ID && x.data.add_action_allowed === 0
  ).length;
});

const actionsFromDemPointsCount = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return 0;
  return props.selectedMarkers.filter(
    (x) => x.data.markerType === ACTION_FROM_DEM_POINT_MARKER_TYPE_ID
  ).length;
});

const actionsFromDemPointsDeactivatedCount = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return 0;
  return props.selectedMarkers.filter(
    (x) =>
      x.data.markerType === ACTION_FROM_DEM_POINT_MARKER_TYPE_ID && x.data.add_action_allowed === 0
  ).length;
});

const actionsWithNoDemPointsCount = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return 0;
  return props.selectedMarkers.filter(
    (x) => x.data.markerType === ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID
  ).length;
});

const demPointsToDeactivateIds = computed(() => {
  if (!isNotEmptyArray(props.selectedMarkers)) return [];

  const demPointsIds = [];

  props.selectedMarkers.forEach((marker) => {
    const type = marker.data.markerType;
    const addActionAllowed = marker.data.add_action_allowed;

    if (type === DEM_POINT_MARKER_TYPE_ID && addActionAllowed === 1) {
      demPointsIds.push(marker.data.markerId);
    } else if (type === ACTION_FROM_DEM_POINT_MARKER_TYPE_ID && addActionAllowed === 1) {
      demPointsIds.push(marker.data.demPointId);
    }
  });

  return demPointsIds;
});

const elementsToDeactivateCount = computed(() => demPointsToDeactivateIds.value.length);
const showDeactivateButton = computed(() => elementsToDeactivateCount.value > 0);

function onDeactivateButtonClicked() {
  if (!isNotEmptyArray(demPointsToDeactivateIds.value)) return;

  const message = `Czy na pewno chcesz dezaktywować wybrane punkty (${elementsToDeactivateCount.value})?`;

  Swal.fire(getSwalConfiguration('Dezaktywacja punktów', message)).then((result) => {
    if (result.value) {
      deactivateDemPoints();
    }
  });
}

async function deactivateDemPoints() {
  const response = await RepositoryDemPoints.deactivateGroupOfDemPoints(
    props.orderId,
    demPointsToDeactivateIds.value
  );

  const toaster = createToaster({
    position: 'top-right',
    duration: 3000
  });

  if (response.data == null) {
    toaster.show('Coś poszło nie tak.', { type: 'error' });
  }

  if (response.data === 'OK') {
    toaster.show('Punkty zostały dezaktywowane.', { type: 'success' });
    emit('pointsDeactivated');
  } else {
    toaster.show(response.data, { type: 'warning' });
  }
}
</script>

<template>
  <div v-if="isNotEmptyArray(selectedMarkers)" class="pl-3">
    <div style="display: flex; justify-content: space-between; align-items: baseline">
      <h4>Podsumowanie zaznaczenia:</h4>
      <button
        v-if="showDeactivateButton"
        @click="onDeactivateButtonClicked"
        type="button"
        class="btn btn-outline btn-info btn-xs"
        style="padding: 0.15rem 0.3rem"
      >
        Dezaktywuj ({{ elementsToDeactivateCount }})
      </button>
    </div>

    <div class="pl-3">
      <div>Liczba punktów: {{ demPointsCount }}</div>
      <div class="pl-3">- w tym dezaktywowanych: {{ demPointsDeactivatedCount }}</div>
      <div>Liczba akcji z punktów: {{ actionsFromDemPointsCount }}</div>
      <div class="pl-3">- w tym dezaktywowanych: {{ actionsFromDemPointsDeactivatedCount }}</div>
      <div>Liczba akcji bez punktów: {{ actionsWithNoDemPointsCount }}</div>
    </div>
  </div>
</template>

<style scoped></style>
