import {
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  SUBCONTRACTOR_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
} from './constants/authConstants';

// login
export const LOGIN_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID,
  SUBCONTRACTOR_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// side menu
export const SIDE_MENU_ORDER_STATUSES_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_GEODESY_PHASES_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_USERS_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_DICTIONARIES_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_TRANSPORT_ORDERS_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_ARCHIVED_ORDERS_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_REPORTS_HISTORY_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SIDE_MENU_ACTIONS_REGISTER_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

export const SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// orders table
export const ORDERS_TABLE_NEW_ORDER_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID
];
export const ORDERS_TABLE_EXPORT_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDERS_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDERS_TABLE_REPORTS_HISTORY_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDERS_TABLE_ARCHIVED_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// orders table filters
export const ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// order details - tabs
export const ORDER_DETAILS_CONTRACTORS_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  SUBCONTRACTOR_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_TRANSPORT_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_GEODETIC_PROTOCOL_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_REPOSITORY_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  SUBCONTRACTOR_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_ADDITIONAL_INFO_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

export const ORDER_DETAILS_MAP_POINTS_REPORTS_TAB_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// order details - buttons
export const ORDER_DETAILS_ARCHIVE_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_EDIT_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];

// transport order details - buttons
export const TRANSPORT_ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];

// actions
export const ADD_ACTIONS_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const EDIT_ACTIONS_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const SHOW_HIDE_ACTIONS_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];
export const REMOVE_ACTIONS_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// demPoints
export const ACTIVATE_DEACTIVATE_DEMPOINT_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// adding workers
export const ADD_WORKER_TO_ORDER_FEATURE_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// settlement comment
export const SETTLEMENT_COMMENT_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// actions map
export const COORDS_DIFFERENCE_METERS_WARNING_THRESHOLD = 10;

// actions register
export const ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES = [
  ADMIN_TYPE_ID,
  DEV_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID
];

// user update
export const UPDATE_USERS_2FA_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];

// other
export const USER_CREATE_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];
export const USER_UPDATE_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];
export const OTHER_USERS_PASSWORD_UPDATE_ACCESS_USER_TYPES = [ADMIN_TYPE_ID, DEV_TYPE_ID];

// dictionaries
export const DICTIONARIES_VIEW_ID_COLUMN_ACCESS_USER_TYPES = [DEV_TYPE_ID];
